import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import OtpInput from 'react-otp-input';

const OtpVerificationPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('60123456789');
    const [otp, setOtp] = useState('');
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [loginData, setLoginData] = useState<any>({});


    const onchangeData = (text: string, field: string) => {
        const inputData = _.cloneDeep(loginData);
        if (text === '') {
            _.unset(inputData, field);
        } else {
            _.set(inputData, field, text);
        }
        setLoginData(inputData);
    };

    return (
        <div className='otpVerificationContainer'>
            <Header back whiteTheme noBorder backAction={() => navigate(-1)} />
            <div className='otpVerificationContainer-body'>
                <div className='otpVerificationContainer-body-labelContainer'>
                    <div className='otpVerificationContainer-body-labelContainer-title'>{t('Let’s confirm it’s you')}</div>
                    <div className='otpVerificationContainer-body-labelContainer-desc'>{t('Enter the code we just sent to')}<strong>+{phoneNumber}</strong></div>
                </div>
                <div className='otpVerificationContainer-body-formContainer'>
                    <div className='otpVerificationContainer-body-formContainer-input'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputType="number"
                            shouldAutoFocus={true}
                            containerStyle={{ width: '100%', justifyContent: 'space-between' }}
                            inputStyle={{ width: '100%', height: '50px', fontSize: '20px', borderRadius: '10px', border: '1px solid #b7b7b7', outline: 'none' }}
                            renderSeparator={<span style={{ width: 32 }}></span>}

                            renderInput={(props) => <input  {...props} className='otpVerificationContainer-body-formContainer-input-box' />}
                        />
                    </div>
                    <div className='otpVerificationContainer-body-resend'>Resend code in <span>4:20</span></div>
                    <Button text={'Continue'} action={() => {

                    }} />
                </div>
            </div>
        </div>
    );
};

export default OtpVerificationPage;
