import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import AccountItem from '../../components/AccountItem';
import accountProfileIcon from '../../assets/icons/accountProfile.svg';
import orderIcon from '../../assets/icons/order.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import loginIcon from '../../assets/icons/login.svg';
import addressIcon from '../../assets/icons/address.svg';
import aboutIcon from '../../assets/icons/about.svg';
import tacIcon from '../../assets/icons/tac.svg';
import ppIcon from '../../assets/icons/pp.svg';
import { useAuth } from '../../contexts/AuthContent';
import { auth } from "../../services/firebase";
import { onAuthStateChanged } from "firebase/auth";

const AccountPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, userSignOut, isAuthenticated, checkAuthentication } = useAuth();

  const data = {
    img: require('../../assets/images/logo.png'),
    name: 'Judy',
    address: 'Kuching, Malaysia'
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, checkAuthentication);
    return unsubscribe;
  }, []);

  return (
    <div className='accountPage'>
      <div className='accountPage-topSection'>
        <div className='accountPage-topSection-avatar'>
          <img src={data?.img || require('../../assets/images/logo.png')} />
        </div>
        <div className='accountPage-topSection-info'>
          <div className='accountPage-topSection-info-name'>
            {user?.email || t('Guest')}
          </div>
          {isAuthenticated && (
            <div className='accountPage-topSection-info-address'>
              {data?.address}
            </div>
          )}
        </div>
      </div>
      <div className='accountPage-menuSection'>
        {isAuthenticated && (
          <div onClick={() => navigate('/editAccount')}>
            <AccountItem label={'Profile'} icon={accountProfileIcon} color={'black'} />
          </div>
        )}
        <div onClick={() => navigate('/orderHistory')}>
          <AccountItem label={'Order History'} icon={orderIcon} color={'black'} />
        </div>
        {isAuthenticated && (
          <div onClick={() => navigate('/addressManagement')}>
            <AccountItem label={'Manage Address'} icon={addressIcon} color={'black'} />
          </div>
        )}
      </div>
      <div className='accountPage-menuSection'>
        <AccountItem label={'About BeReach'} icon={aboutIcon} color={'black'} />
        <AccountItem label={'Terms & Conditions'} icon={tacIcon} color={'black'} />
        <AccountItem label={'Privacy Policy'} icon={ppIcon} color={'black'} />
        {isAuthenticated ? (
          <div onClick={() => userSignOut()}>
            <AccountItem label={'Log out'} icon={logoutIcon} color={'red'} />
          </div>
        ) : (
          <div onClick={() => navigate('/login')}>
            <AccountItem label={'Log In'} icon={loginIcon} color={'green'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountPage;
