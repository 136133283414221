import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import AccountInputBox from '../../components/AccountInputBox';

const InputPhoneNumberPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [loginData, setLoginData] = useState<any>({});


    const onchangeData = (text: string, field: string) => {
        const inputData = _.cloneDeep(loginData);
        if (text === '') {
            _.unset(inputData, field);
        } else {
            _.set(inputData, field, text);
        }
        setLoginData(inputData);
    };

    return (
        <div className='inputPhoneNumberContainer'>
            <Header back whiteTheme noBorder backAction={() => navigate(-1)} />
            <div className='inputPhoneNumberContainer-body'>
                <div className='inputPhoneNumberContainer-body-labelContainer'>
                    <div className='inputPhoneNumberContainer-body-labelContainer-title'>{t('Enter your mobile number')}</div>
                    <div className='inputPhoneNumberContainer-body-labelContainer-desc'>{t('We’ll send you a verification code.')}</div>
                </div>
                <div className='inputPhoneNumberContainer-body-formContainer'>
                    <div className='inputPhoneNumberContainer-body-formContainer-input'>
                        <AccountInputBox
                            label={'Mobile number'}
                            inputValue={loginData?.mobileNumber ?? ''}
                            text={'Your mobile number'}
                            type='number'
                            setInputValue={(e) => onchangeData(e, 'mobileNumber')}
                        />
                    </div>
                    <Button text={'Send code'} action={() => navigate('/otpVerification')} />
                </div>
            </div>
        </div>
    );
};

export default InputPhoneNumberPage;
