import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useLoading } from './LoadingContent';
import { SIGN_IN } from '../query/signIn'
import { useApolloClient, ApolloClient } from '@apollo/client';
import { UserType } from '../types/dataType';
import { useNotification } from '../contexts/NotificationContext';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { auth } from "../services/firebase";
import { onAuthStateChanged, User, signOut } from "firebase/auth";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserType | null;
  setUser: (user: UserType | null) => void;
  // userSignIn: (
  //   type: string,
  //   email: number | undefined,
  //   password: string | undefined
  // ) => void;
  userSignOut: () => void;
  checkAuthentication: (currentUser?: User | null) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserType | null>(null);
  const { showLoading, hideLoading } = useLoading() || {};
  const apolloClient: ApolloClient<any> = useApolloClient();
  const navigate = useNavigate();
  const { showNotification } = useNotification();


  const checkAuthentication = async (currentUser?: User | null) => {
    let userData: UserType | null = null;

    if (currentUser) {
      userData = {
        _id: currentUser.uid,
        email: currentUser.email,
        phoneNumber: currentUser.phoneNumber,
      };
    } else {
      const savedUser = Cookies.get('user');
      if (savedUser) {
        userData = JSON.parse(savedUser);
      }
    }

    if (userData) {
      setUser(userData);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false); // Explicitly set to false if no user found
      setUser(null);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, checkAuthentication);
    return unsubscribe;
  }, []);

  // const userSignIn = async (
  //   type: string,
  //   email: number | undefined,
  //   password: string | undefined
  // ) => {
  //   showLoading?.()
  //   if (email && password && type === 'general_account') {
  //     const newData = {
  //       type: type,
  //       email: email,
  //       password: password
  //     }
  //     let result = await apolloClient.mutate({
  //       mutation: SIGN_IN,
  //       variables: { input: newData },
  //     })

  //     if (result?.data) {
  //       if (result?.data?.signIn?.result) {
  //         const loggedInUser = result.data.signIn.result;
  //         Cookies.set('user', JSON.stringify(loggedInUser), { expires: 365 * 10, secure: true, sameSite: 'Strict' });
  //         setIsAuthenticated(true);
  //         setUser(loggedInUser);
  //         showNotification('Welcome, ' + loggedInUser.email, 'success')
  //         navigate('/');
  //       }
  //     }
  //   }
  //   hideLoading?.();
  // };

  const userSignOut = async () => {
    try {
      await logout();
      setIsAuthenticated(false);
      setUser(null);
      Cookies.remove('user');
      showNotification('Logout successful', 'success');
      navigate('/login');
    } catch (error) {
      // Handle logout error
      showNotification('Logout failed', 'error');
      console.error("Logout Error:", error)
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout Error:", error);
      // Re-throw the error to be handled by userSignOut
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, userSignOut, checkAuthentication }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};