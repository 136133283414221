import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { CartItem, Item, Address, StoreCart, OptionGroup, PaymentMethod, Option, TaxList, OrderHistory } from '../types/dataType';
import * as _ from 'lodash';
import { useNotification } from '../contexts/NotificationContext';
import { fullBrowserVersion, browserName, engineName, mobileVendor, deviceType, osName } from "react-device-detect";
import { CREATE_PRE_ORDER } from '../query/preOrder'
import { CALCULATE_ORDER } from '../query/calculateOrder'
import { GENERATE_ORDER } from '../query/paidGenerateOrder'
import { useApolloClient, ApolloClient } from '@apollo/client';
import { useLoading } from './LoadingContent';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CartContextProps {
    cart: CartItem[];
    setToCart: (
        item: Item,
        store: StoreCart | undefined,
        addOn: OptionGroup[],
        quantity: number,
        remark: string
    ) => void;
    EditToCart: (
        id: number,
        store: StoreCart | undefined,
        addOn: OptionGroup[],
        quantity: number,
        remark: string
    ) => void;
    totalAmount: number;
    method: string | undefined;
    payAmount: number;
    selectMethod: (
        method?: string,
    ) => void;
    selectTable: (
        tableNumber?: string,
    ) => void;
    tableNumber: string | undefined;
    pickAddress: (
        address: Address
    ) => void;
    selectedAddress?: Address;
    setSelectedPaymentGateway: (
        paymentMethod: PaymentMethod,
    ) => void;
    selectedPaymentGateway?: PaymentMethod
    preOrder: () => Promise<void>;
    cleanData: () => Promise<void>;
    taxList: TaxList[];
    stroageToLocalOrderHistory: (
        billGroupDetail: OrderHistory,
    ) => void;
}


const CartContext = createContext<CartContextProps | undefined>(undefined);

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [cart, setCart] = useState<CartItem[]>([]);
    const [taxList, setTaxList] = useState<TaxList[]>([]);
    const { showLoading, hideLoading } = useLoading() || {};
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [deliveryAmount, setDeliveryAmount] = useState<number>(0);
    const [payAmount, setPayAmount] = useState<number>(0);
    const [method, setMethod] = useState<string | undefined>(undefined);
    const [tableNumber, setTableNumber] = useState<string | undefined>(undefined);
    const [nextId, setNextId] = useState<number>(1);
    const [spayPaymentData, setSpayPaymentData] = useState<object>({});
    const { showNotification } = useNotification();
    const [loadCart, setLoadCart] = useState<boolean>(false);
    const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(undefined);
    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState<PaymentMethod | undefined>(undefined);
    const navigate = useNavigate();
    const apolloClient: ApolloClient<any> = useApolloClient();
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        getLocalCartData();
    }, [])

    const getLocalCartData = async () => {
        const qrExpire = await localStorage.getItem('qr-exp-date');

        if (dayjs().isAfter(dayjs(qrExpire))) {
            await localStorage.removeItem('cart');
        } else {
            const storageData = localStorage.getItem('cart')
            if (storageData) {
                const carts = JSON.parse(storageData)
                if (carts?.cart && carts?.cart.length !== 0) {
                    setCart(carts?.cart)
                    setNextId(carts?.cart?.length + 1)
                }
                if (carts?.method && carts?.method.length !== 0) {
                    setMethod(carts?.method)
                }
                if (carts?.table) {
                    setTableNumber(carts?.table)
                }
                if (carts?.deliveyAddress) {
                    setSelectedAddress(carts?.deliveyAddress)
                }
                if (carts?.paymentMethod) {
                    setSelectedPaymentGateway(carts?.paymentMethod)
                }
            }
        }
        setLoadCart(true)
    }

    const setToCart = (
        item: Item,
        store: StoreCart | undefined,
        option: OptionGroup[] | undefined,
        quantity: number,
        remark: string
    ) => {
        const existingItemIndex = cart.findIndex(
            (cartItem) =>
                cartItem.item._id === item._id &&
                JSON.stringify(cartItem.item.option_group_list) === JSON.stringify(option)
                && cartItem.remark === remark
        );

        const updatedAddOnWithBalance = calculateTotalAmounts(option ?? []);

        const totalSum = updatedAddOnWithBalance?.reduce((sum, group) => {
            return sum + (group.totalAmount ?? 0);
        }, 0);

        const totalAmount = ((item.price ?? 0) + (totalSum ?? 0)) * quantity;
        if (existingItemIndex !== -1) {
            const updatedCart = [...cart];
            const existingItem = updatedCart[existingItemIndex];
            existingItem.quantity += quantity;
            existingItem.totalAmount += totalAmount;

            if (existingItem.quantity <= 0) { updatedCart.splice(existingItemIndex, 1); }
            setCart(updatedCart);
        } else {
            if (quantity > 0) {
                const newItem = {
                    id: nextId,
                    item,
                    store,
                    addOn: updatedAddOnWithBalance || [],
                    quantity,
                    totalAmount,
                    remark
                };
                setCart([...cart, newItem]);
                setNextId(nextId + 1);
            }
        }
    };

    const calculateTotalAmounts = (groups: OptionGroup[]) => {
        return groups.map(group => {

            const updatedOptions = group?.options?.map(option => {
                const qty = option?.qty ?? 0;
                const price = option?.price ?? 0;
                const totalAmount = qty * price;
                return {
                    ...option,
                    totalAmount: totalAmount
                };
            });


            const totalAmountForGroup = updatedOptions?.reduce((sum, option) => {
                return sum + option.totalAmount;
            }, 0);


            return {
                ...group,
                options: updatedOptions,
                totalAmount: totalAmountForGroup
            };
        });
    };

    const EditToCart = (
        id: number,
        store: StoreCart | undefined,
        option: OptionGroup[] | undefined,
        quantity: number,
        remark: string
    ) => {
        setCart((prevCart) => {
            const updatedCart = prevCart.map((cartItem) => {
                if (cartItem.id === id) {
                    const updatedAddOnWithBalance = calculateTotalAmounts(option ?? []);
                    const totalSum = updatedAddOnWithBalance?.reduce((sum, group) => {
                        return sum + (group.totalAmount ?? 0);
                    }, 0);

                    const totalAmount = ((cartItem.item.price ?? 0) + (totalSum ?? 0)) * quantity;

                    return {
                        ...cartItem,
                        store,
                        addOn: updatedAddOnWithBalance,
                        quantity,
                        totalAmount,
                        remark
                    };
                }
                return cartItem;
            });


            return updatedCart.filter((cartItem) => cartItem.quantity > 0);
        });
    };

    const selectMethod = (method?: string) => {
        setMethod(method);
        if (method === 'delivery') {
            setTableNumber(undefined);
        }
    };

    const pickAddress = (address?: Address) => {
        setSelectedAddress(address);
    };

    const selectTable = (tableNumber?: string) => {
        setTableNumber(tableNumber);
    };

    useEffect(() => {
        if (loadCart) {
            if (!_.isEmpty(cart)) {
                getTotalOrderAmount(cart);
            } else {
                setTotalAmount(0);
                setPayAmount(0);
            }
            const data = {
                method: method,
                cart: cart,
                table: tableNumber,
                deliveyAddress: selectedAddress,
                paymentMethod: selectedPaymentGateway
            }
            // console.log('cart', data)
            console.log(`Data size: ${JSON.stringify(data).length} bytes`);
            localStorage.setItem('cart', JSON.stringify(data));
        }
    }, [loadCart, cart, method, tableNumber, selectedAddress, selectedPaymentGateway]);

    const getTotalOrderAmount = async (cart: CartItem[]) => {
        try {
            const companyId = await localStorage.getItem('companyId');
            const product_list = await simplayCartItem(cart);
            // console.log('product_list', product_list)
            let input;
            if (method === 'delivery' && !_.isEmpty(selectedAddress)) {
                input = {
                    company_id: companyId,
                    productList: product_list,
                    deliveryAddress: {
                        lat: selectedAddress?.lat,
                        lng: selectedAddress?.long,
                        place_name: selectedAddress?.place_name
                    },
                }
            } else {
                input = {
                    company_id: companyId,
                    productList: product_list
                }
            }
            const result = await apolloClient.query({
                query: CALCULATE_ORDER,
                variables: {
                    input: input
                },
            });
            // console.log('getTotalOrderAmount', result)
            if (result?.data?.calculateOrderAmount) {
                setTotalAmount(result?.data?.calculateOrderAmount?.gross_price)
                setPayAmount(result?.data?.calculateOrderAmount?.net_price);
                setTaxList(result?.data?.calculateOrderAmount?.product_tax_list);
                setDeliveryAmount(result?.data?.calculateOrderAmount?.delivery_price)
            } else {
                showNotification('Something went wrong.', 'error');
                hideLoading?.();
            }
        } catch (error) {
            console.error('Error calculating order amount:', error);
        }
    }

    // const combineTaxes = (taxes: TaxList[]): TaxList[] => {
    //     const combinedTaxes: Record<string, TaxList> = {};

    //     taxes.forEach(({ label, percentage, total_price }) => {
    //         const key = `${label}_${percentage}`;

    //         if (combinedTaxes[key]) {

    //             combinedTaxes[key].total_price = (combinedTaxes[key].total_price ?? 0) + (total_price ?? 0);
    //         } else {
    //             combinedTaxes[key] = { label, percentage, total_price };
    //         }
    //     });

    //     return Object.values(combinedTaxes);
    // };

    const simplayCartItem = async (cartItem: CartItem[]) => {
        return Promise.all(cartItem.map(async item => ({
            product_id: item?.item?._id,
            qty: item?.quantity,
            options: await simplayOptionGroup(item?.addOn ?? []),
        })));
    };

    const simplayOptionGroup = (optionGroups: OptionGroup[]) => {
        return Promise.all(optionGroups.map(async item => ({
            group_id: item?._id?.toString(),
            selected: simplayOption(item?.options ?? []),
        })));
    };

    const simplayOption = (options: Option[]) => {
        return options.map(item => ({
            name: item?.name,
            qty: item?.qty ?? 0,
        }));
    };

    const preOrder = async () => {
        try {
            showLoading?.()
            const qr = localStorage.getItem('qr');
            if (qr) {
                let newData;
                if (method === 'delivery' && !_.isEmpty(selectedAddress)) {
                    newData = {
                        // table_no: tableNumber ? tableNumber : null,
                        productList: await simplayOrderItem(cart),
                        order_type: method,
                        deliveryAddress: {
                            lat: selectedAddress?.lat,
                            lng: selectedAddress?.long,
                            place_name: selectedAddress?.place_name
                        },
                        // payment_type: selectedPaymentGateway?.value,
                        qr_code: qr,
                        device: `type: ${deviceType} - ${browserName} - ${fullBrowserVersion} - ${engineName} , Mobile: ${mobileVendor}`,
                        firebase_user_id: null
                    }
                } else {
                    newData = {
                        // table_no: tableNumber ? tableNumber : null,
                        productList: await simplayOrderItem(cart),
                        order_type: method,
                        // payment_type: selectedPaymentGateway?.value,
                        qr_code: qr,
                        device: `type: ${deviceType} - ${browserName} - ${fullBrowserVersion} - ${engineName} , Mobile: ${mobileVendor}`,
                        firebase_user_id: null
                    }
                }
                let result = await apolloClient.mutate({
                    mutation: CREATE_PRE_ORDER,
                    variables: { input: newData },
                })
                console.log('selectedPaymentGateway', selectedPaymentGateway)
                if (result?.data && selectedPaymentGateway) {
                    if (selectedPaymentGateway?.value === 'cash' || selectedPaymentGateway?.value === 'H5-Spay' || selectedPaymentGateway?.value === 'cash-1') {
                        createOrder(result?.data?.createPreOrder?.result?._id, selectedPaymentGateway?.value)
                    } else {
                        // alert('Currently not supported');
                        showNotification('Currently not supported.', 'error');
                        hideLoading?.();
                    }
                } else {
                    showNotification('Something went wrong.', 'error');
                    hideLoading?.();
                }

            } else {
                hideLoading?.();
                navigate('/')
            }
        } catch (err) {
            hideLoading?.();
            console.log(err)
            throw err
        }
    }

    const simplayOrderItem = async (cartItem: CartItem[]) => {
        return Promise.all(cartItem.map(async item => ({
            product_id: item?.item?._id,
            store_id: item?.store?._id,
            qty: item?.quantity,
            remark: item?.remark ?? "",
            options: await simplayOrderOptionGroup(item?.addOn ?? []),
        })));
    };

    const simplayOrderOptionGroup = (optionGroups: OptionGroup[]) => {
        return Promise.all(optionGroups.map(async item => ({
            group_id: item?._id?.toString(),
            selected: simplayOrderOption(item?.options ?? []),
        })));
    };

    const simplayOrderOption = (options: Option[]) => {
        return options.map(item => ({
            name: item?.name,
            qty: item?.qty ?? 0,
        }));
    };

    const createOrder = async (orderId: string, paymentMethod: string) => {
        try {
            showLoading?.()
            if (orderId && paymentMethod) {
                const newData = {
                    pre_order_id: orderId,
                    payment_type: paymentMethod
                }
                // console.log('paymentMethod', paymentMethod)
                let result = await apolloClient.mutate({
                    mutation: GENERATE_ORDER,
                    variables: { input: newData },
                })
                // await localStorage.removeItem('cart');
                // console.log('createOrder', result?.data)
                if (result?.data) {
                    if (result?.data?.generateOrder?.payment_type === 'H5-Spay') {
                        await setSpayPaymentData(result?.data?.generateOrder?.additional_info?.spay);
                    } else {
                        const companyName = localStorage.getItem('companyName')
                        const data = {
                            'company_name': companyName,
                            'date': dayjs().format('dddd, MMM D hh:mm'),
                            'total_amount': payAmount,
                            'payment_method': result?.data?.generateOrder?.payment_type,
                            'order_group_id': result?.data?.generateOrder?.bill_group_no,
                        }
                        stroageToLocalOrderHistory(data);
                        cleanData();
                        hideLoading?.();
                        navigate(`/order/${result?.data?.generateOrder?.bill_group_no}`)
                    }
                } else {
                    showNotification('Something went wrong.', 'error');
                    hideLoading?.();
                }

                hideLoading?.();
            } else {
                hideLoading?.();
                // navigate('/')
            }
        } catch (err) {
            hideLoading?.();
            console.log(err)
            throw err
        }
    }

    const stroageToLocalOrderHistory = (billGroupDetail: OrderHistory) => {
        localStorage.setItem('latest_bill_group_no', billGroupDetail?.order_group_id?.toString() ?? '');
        const billHistory = localStorage.getItem('bill_history_list')
        if (billHistory) {
            const billHistoryJson = JSON.parse(billHistory);
            const exists = billHistoryJson.some(
                (bill: any) => bill.order_group_id === billGroupDetail.order_group_id
            );
            if (!exists) {
                billHistoryJson.push(billGroupDetail);
            }
            localStorage.setItem('bill_history_list', JSON.stringify(billHistoryJson));
        } else {
            const billHistoryJson: OrderHistory[] = [];
            billHistoryJson.push(billGroupDetail);
            localStorage.setItem('bill_history_list', JSON.stringify(billHistoryJson));
        }
    }

    const cleanData = async () => {
        setCart([]);
        setMethod('');
        setTableNumber('');
        setSelectedAddress(undefined);
        setSelectedPaymentGateway(undefined);
        setTotalAmount(0);
        setPayAmount(0);
        setDeliveryAmount(0)
    }

    useEffect(() => {
        if (!_.isEmpty(spayPaymentData)) {

            localStorage.setItem('latest_merOrderNo', (spayPaymentData as { merOrderNo: string })?.merOrderNo ?? '');
            const encodedArray = encodeURIComponent(JSON.stringify(spayPaymentData));
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari) {
                setShowAlert(true)
            } else {
                window.open(`${window.location.origin}/spay?data=${encodedArray}`, '_blank');
                navigate(`/paymentStatus?merOrderNo=${_.get(spayPaymentData, ['merOrderNo'], '')}`);
            }
        }
    }, [spayPaymentData]);

    const handleClick = () => {
        const encodedArray = encodeURIComponent(JSON.stringify(spayPaymentData));
        window.open(`${window.location.origin}/spay?data=${encodedArray}`, '_blank');
        navigate(`/paymentStatus?merOrderNo=${_.get(spayPaymentData, ['merOrderNo'], '')}`);
        setShowAlert(false)
    }

    const alertContainerStyles: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
    };

    const alertBoxStyles: React.CSSProperties = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };

    const buttonStyles: React.CSSProperties = {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: 'orange',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };


    return (
        <CartContext.Provider value={{ cart, totalAmount, setToCart, EditToCart, payAmount, method, selectMethod, selectTable, tableNumber, pickAddress, setSelectedPaymentGateway, selectedPaymentGateway, preOrder, selectedAddress, cleanData, taxList, stroageToLocalOrderHistory }}>
            {showAlert && (
                <div style={alertContainerStyles}>
                    <div style={alertBoxStyles}>
                        <p>Open New Tab For Spay</p>
                        <button onClick={() => handleClick()} style={buttonStyles}>
                            Run Spay
                        </button>
                    </div>
                </div>
            )}
            {children}
        </CartContext.Provider>
    );


};
