import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import LinkPath from '../../components/LinkPath';
import config from '../../lib/config';
import Header from '../../components/Header';
import AccountInputBox from '../../components/AccountInputBox';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../services/firebase";
import { useNotification } from '../../contexts/NotificationContext';
import { useLoading } from '../../contexts/LoadingContent';

const EmailRegisterPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [registerData, setRegisterData] = useState<any>({});
    const { showNotification } = useNotification();
    const { showLoading, hideLoading } = useLoading() || {};

    useEffect(() => {
        console.log('Player Data', registerData)
        const inputData = _.cloneDeep(errors);
        if (registerData?.usernameEmail === '' || !registerData?.usernameEmail) {
            _.set(inputData, 'usernameEmail', 'required');
        } else {
            _.unset(inputData, 'usernameEmail');
        }

        if ((registerData?.password === '' || !registerData?.password)) {
            _.set(inputData, 'password', 'required');
        } else {
            _.unset(inputData, 'password');
        }
        if ((registerData?.confirm_password === '' || !registerData?.confirm_password)) {
            _.set(inputData, 'confirm_password', 'required');
        } else if (registerData?.confirm_password !== registerData?.password) {
            _.set(inputData, 'confirm_password', 'Password does not match');
        } else {
            _.unset(inputData, 'confirm_password');
        }
        console.log('errors', inputData)
        setErrors(inputData);
    }, [registerData]);


    const onchangeData = (text: string, field: string) => {
        const inputData = _.cloneDeep(registerData);
        if (text === '') {
            _.unset(inputData, field);
        } else {
            _.set(inputData, field, text);
        }
        setRegisterData(inputData);
    };


    const submit = async () => {
        setTriggerSubmit(true);
        showLoading?.();
        if (_.isEmpty(errors)) {
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, registerData.usernameEmail, registerData.password);
                console.log("User Info:", userCredential.user);
                hideLoading?.();
                showNotification('Registration successful, ' + registerData.usernameEmail, 'success')
            } catch (error: any) {
                console.log("Login Error:", error);
                switch (error.code) {
                    case "auth/email-already-in-use)":
                        showNotification("Email have been used.", 'error');
                        break;
                    case "auth/invalid-email":
                        showNotification("Invalid email.", 'error');
                        break;
                    case "auth/weak-password":
                        showNotification("Password should be at least 6 characters.", 'error');
                        break;
                    default:
                        showNotification("Login failed. Please try again.", 'error');
                        break;
                }
                hideLoading?.();
            }
        }
    }



    return (
        <div className='phoneNumberContainer'>
            <Header back whiteTheme noBorder backAction={() => navigate('/emailLogin')} />
            <div className='phoneNumberContainer-body'>
                <div className='phoneNumberContainer-body-labelContainer'>
                    <div className='phoneNumberContainer-body-labelContainer-title'>{t('sign up')}</div>
                    <div className='phoneNumberContainer-body-labelContainer-desc'>{t('Let’s get started!')}</div>
                </div>
                <div className='phoneNumberContainer-body-formContainer'>
                    <div className='phoneNumberContainer-body-formContainer-input'>
                        <AccountInputBox
                            label={'Username or email'}
                            inputValue={registerData?.usernameEmail ?? ''}
                            text={'Username or email'}
                            setInputValue={(e) => onchangeData(e, 'usernameEmail')}
                        />
                    </div>
                    <div className='phoneNumberContainer-body-formContainer-input'>
                        <AccountInputBox
                            label={'Password'}
                            inputValue={registerData?.password ?? ''}
                            text={'Password'}
                            type={'password'}
                            setInputValue={(e) => onchangeData(e, 'password')}
                        />
                    </div>
                    <div className='phoneNumberContainer-body-formContainer-input'>
                        <AccountInputBox
                            label={'Confirm Password'}
                            inputValue={registerData?.confirm_password ?? ''}
                            text={'Confirm Password'}
                            type={'password'}
                            setInputValue={(e) => onchangeData(e, 'confirm_password')}
                        />
                    </div>
                </div>
                <div className='phoneNumberContainer-footer'>
                    <div className='phoneNumberContainer-footer-linkContainer'>
                        <div className='phoneNumberContainer-footer-linkContainer-text'>
                            {t('Already Register?')}
                        </div>
                        <a className='phoneNumberContainer-footer-linkContainer-link' onClick={() => navigate('/emailLogin')}>
                            {t('Log in')}
                        </a>
                    </div>
                    <Button text={'sign up'} action={() => {
                        submit()
                    }} />
                </div>
            </div>
        </div>
    );
};

export default EmailRegisterPage;
