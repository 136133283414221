import React, { useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import showIcon from '../../assets/icons/hide.svg';
import hideIcon from '../../assets/icons/hide.svg';

interface AccountInputBoxProps {
  inputValue: string;
  label?: string;
  text?: string;
  setInputValue: (value: string) => void;
  type?: string;
}

const AccountInputBox: React.FC<AccountInputBoxProps> = ({
  inputValue,
  setInputValue,
  label,
  text,
  type = 'text'
}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  return (
    <div className='accountInputBox'>
      <div className='accountInputBox-label'>{t(label ?? '')}</div>
      <input type={type === 'password' && visible ? 'text' : type} value={inputValue} onChange={handleChange} placeholder={text} />
      {type === 'password' && (
        <div className='accountInputBox-eyeIcon' onClick={() => setVisible(!visible)}>
          {visible ? (<img style={{ width: 20, height: 20 }} src={hideIcon} />) : (<img style={{ width: 20, height: 20 }} src={showIcon} />)}
        </div>)}
    </div>
  );
};

export default AccountInputBox;
